.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 65px;
}

.container h2 {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
  padding-bottom: 10px;
}

.container > p {
  font-size: 0.8rem;
  color: gray;
  padding-bottom: 40px;
}

.input_container {
  width: 100%;
  padding-inline: 20px;
}

.input_type1 {
  height: 50px;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.input_type1 svg {
  height: 20px;
  width: 20px;
}

.input_type1 input {
  height: 100%;
  flex-grow: 1;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  padding-inline: 10px;
}

.input_type1 input:focus {
  outline: none;
}

.input_type2 {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 5px;
  font-size: 0.8rem;
  color: gray;
}

.input_type2 span {
  color: black;
  cursor: pointer;
  font-weight: 600;
}

.input_type2 a {
  color: black;
  text-decoration: none;
}

.btn_section {
  width: 100%;
  padding-inline: 20px;
  margin-top: 40px;
}

.btn_section button {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  background-color: black;
  border: none;
  color: white;
  font-size: 1rem;
}

.secured_section {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  font-size: 0.8rem;
}

.secured_section svg {
  height: 1rem;
  margin-right: 1px;
}

.secured_section img {
  height: 1.4rem;
  width: auto;
}

/* overlay  */
.overlay {
  position: absolute;
  height: 60%;
  width: 100%;
  background: white;
  left: 0;
  bottom: 0;
  transform: translateY(150%);
  border-radius: 15px 15px 0 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  transition: all 0.5s;
}

.overlay_show {
  transform: translateY(0);
}

.overlay_head {
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay_head h2 {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
}

.overlay_head svg {
  position: absolute;
  right: 20px;
}

.email_section {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  color: gray;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
}

.email {
  display: flex;
  align-items: center;
  gap: 5px;
  color: gray;
  font-size: 0.9rem;
}

.email svg {
  height: 18px;
  width: 18px;
  fill: grey;
  cursor: pointer;
}

.otp_section {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding-inline: 50px;
  gap: 10px;
  margin-top: 50px;
}

.otp {
  width: 100%;
  aspect-ratio: 1/1;
  border: 2px solid lightgray;
  border-radius: 5px;
}

.otp input {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 5px;
  font-size: 01rem;
  text-align: center;
  -moz-appearance: textfield;
}

.otp input::-webkit-outer-spin-button,
.otp input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp input:focus {
  outline: 2px solid black;
}

.otp_timer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  gap: 5px;
}

.error input {
  outline: 2px solid red;
  background: rgba(255, 0, 0, 0.2);
}

.success input {
  outline: 2px solid green;
  background: rgba(0, 255, 0, 0.2);
}
/* overlay ends */

.success_container {
  position: absolute;
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}
