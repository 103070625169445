.address_type {
}

.address_type p {
  font-size: 0.8rem;
}

.address_type ul {
  list-style: none;
  display: flex;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  flex-wrap: wrap;
}

.address_type ul li {
  padding: 10px 10px;
  border: 1px solid black;
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
}

.address_type ul li svg {
  height: 15px;
}

.address_type ul li.active {
  background-color: black;
  color: white;
}

.address_type ul li.active svg path {
  fill: white;
}
