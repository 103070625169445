.iwl_container {
  height: 50px;
  border: 1px solid lightgrey;
  border-radius: 15px;
  position: relative;
}

.iwl_label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  color: grey;
  z-index: 0;
  font-size: 0.9rem;
  transition: 0.5s;
  letter-spacing: 0.05rem;
}

.iwl_label_active {
  font-size: 0.8rem;
  top: 0;
  background: white;
  padding-inline: 3px;
  color: black;
  z-index: 5;
}

.iwl_container input {
  position: relative;
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  font-size: 0.9rem;
  background: transparent;
  z-index: 2;
}

.iwl_container input:focus {
  outline: none;
}
