.main {
  height: fit-content;
  overflow-y: auto;
  padding-inline: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 20px;
}

.order_summary {
  border: 1px solid lightgrey;
  min-height: 50px;
  flex-shrink: 0;
  border-radius: 15px;
}

.order_summary_head {
  height: 50px;
  display: flex;
  align-items: center;
  padding-inline: 15px;
  gap: 10px;
  font-size: 0.9rem;
  color: #1f1f1f;
  cursor: pointer;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}

.order_summary_head svg {
  height: 20px;
  transition: all 0.5s;
  width: fit-content;
}

.order_summary_head_expand {
  border-bottom: 1px solid grey;
}

.order_summary_head_expand svg:last-child {
  transform: rotate(180deg);
}

.order_summary_body {
  max-height: 0;
  transition: 0.5s;
  overflow: hidden;
}

.order_summary_body_expand {
  max-height: 100vh;
}

.orders {
  list-style: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.orders li {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-inline: 15px;
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.order_price {
  padding-top: 3px;
  flex-shrink: 0;
  font-size: 0.8rem;
}

.order_img_and_content {
  flex-grow: 1;
  display: flex;
  gap: 10px;
}

.order_img {
  height: 55px;
  width: 55px;
  background: lightblue;
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
}

.order_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.order_content {
  padding-top: 3px;
  text-transform: capitalize;
}

.order_content h3 {
  font-size: 0.8rem;
  font-weight: 500;
}

.order_content p {
  font-size: 0.7rem;
  margin-top: 5px;
}

.order_total {
  display: flex;
  flex-direction: column;
  padding-top: 0px !important;
  gap: 0 !important;
  height: fit-content;
}

.order_total_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-inline: 0px;
  color: grey;
  font-size: 0.9rem;
}

.grand_total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  font-weight: 600;
  font-size: 1.2rem;
}

.coupon,
.phone,
.proceed_btn,
.input {
  height: 50px;
  border: 1px solid lightgrey;
  width: 100%;
  border-radius: 15px;
}

.proceed_btn {
  background: black;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-size: 1.1rem;
  cursor: pointer;
  flex-shrink: 0;
}

.checkbox_with_text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
  color: grey;
}

.terms_and_conditions {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: grey;
}

.terms_and_conditions span {
  color: #1f1f1f;
}

.terms_and_conditions p:last-child {
  padding-top: 10px;
}

/* Coupon */
.coupon {
  display: flex;
  align-items: center;
  padding-left: 15px;
  gap: 10px;
  flex-shrink: 0;
  padding-right: 20px;
  cursor: pointer;
}

.coupon p {
  flex-grow: 1;
  font-size: 0.9rem;
}

.coupon svg {
  height: 20px;
  width: 20px;
}

/* Coupon Ends */

/* ADDRESS  */
.address {
  border: 1px solid lightgrey;
  min-height: 50px;
  flex-shrink: 0;
  border-radius: 15px;
}

.address_head {
  height: 50px;
  display: flex;
  align-items: center;
  padding-inline: 15px;
  gap: 10px;
  font-size: 0.9rem;
  color: #1f1f1f;
  cursor: pointer;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}

.address_head svg:first-child {
  height: 20px;
}

.address_head svg:last-child {
  height: 24px;
  width: 24px;
  transform: rotate(0deg);
  transition: all 0.5s;
}

.address_head_expand svg:last-child {
  transform: rotate(180deg);
}

.address_head span {
  display: inline-block;
  font-size: 0.8rem;
  color: grey;
  margin-left: auto;
}

.address_head p {
  font-size: 0.9rem;
}

.addresses {
  /* padding: 0px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 0;
  overflow: hidden; */
  max-height: 0;
  transition: 0.5s;
  overflow: hidden;
}

.addresses_expand {
  max-height: 100vh;
}

.address_wrapper {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex-shrink: 0;
}

.add_new_address {
  flex-shrink: 0;
  height: 50px;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  gap: 10px;
  cursor: pointer;
}

.add_new_address p {
  font-size: 0.9rem;
}
.add_new_address svg {
  height: 20px;
  width: 20px;
}

.old_address {
  height: fit-content;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 15px;
  padding-left: 15px;
  padding-top: 15px;
  padding-right: 15px;
}

.old_address_head {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
}

.old_address_head input {
  height: 20px;
  width: 20px;
}

.old_address_head span {
  background: rgb(220, 220, 220);
  padding: 5px 10px;
  border-radius: 8px;
  margin-right: auto;
}

.old_address_head svg {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.old_address_head svg path {
  fill: grey;
  margin-left: auto;
}

.old_address_head svg:last-child path {
  fill: red;
}

.old_address_val {
  padding-bottom: 15px;
}
.old_address_val p {
  font-size: 0.8rem;
  color: grey;
}

.old_address_val p:first-child {
  font-size: 0.9rem;
  color: black;
  padding-bottom: 3px;
}
/* ADDRESS ENDS */

/* coupon overlay */

.input {
  display: flex;
  align-items: center;
  padding-right: 15px;
}

.input input {
  flex-grow: 1;
  height: 100%;
  border: none;
  border-radius: 20px;
  padding: 20px;
  font-size: 1.1rem;
}

.input input:focus {
  outline: none;
}

.input button {
  background: transparent;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.07rem;
  color: orange;
}

.coupons {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 20px;
}

.coupon_card {
  width: 100%;
  height: 150px;
  border: 1px dashed lightgrey;
  border-radius: 20px;
  padding: 15px;
}

.coupon_head {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.coupon_head button {
  margin-left: auto;
  background: transparent;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: 0.01rem;
  cursor: pointer;
}

.coupon_head span {
  font-size: 1.1rem;
  font-weight: 500;
}

.coupon_card p {
  margin-top: 10px;
  font-size: 1rem;
  line-height: 1.5rem;
}

/* .coupon_card {
  height: 150px;
  width: 100%;
  background: rgb(224, 224, 224);
  border-radius: 20px;
}

.coupon_card_type {
  height: 100%;
  background: orange;
  width: 50px;
  position: relative;
  border-radius: 20px 0 0 20px;
}

.coupon_card_type p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  color: white;
  letter-spacing: 0.13rem;
  font-weight: 600;
  white-space: nowrap;
}

.coupon_punch {
  position: absolute;
  list-style: none;
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 7px;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.coupon_punch li {
  height: 10px;
  width: 10px;
  background: white;
  border-radius: 100%;
} */
/* coupon overlay ends */

/* account info */
.account_info {
  height: 50px;
  display: flex;
  align-items: center;
  padding-inline: 15px;
  font-size: 0.9rem;
  gap: 10px;
}

.account_info svg {
  height: 20px;
  width: fit-content;
}

.account_info svg:last-child {
  margin-left: auto;
}
/* account info end */

/* payment */
.payment_head {
  height: 50px;
  display: flex;
  align-items: center;
  padding-inline: 15px;
  gap: 10px;
}

.payment_head p {
  font-size: 0.9rem;
}

.payment_head span {
  font-size: 0.8rem;
  color: gray;
  margin-left: auto;
}

.payment_head svg {
  height: 20px;
}

.payment_head svg:last-child {
  height: 24px;
  width: 24px;
  transform: rotate(0deg);
  transition: all 0.5s;
  cursor: pointer;
}

.payment_head_expand svg:last-child {
  transform: rotate(180deg);
}

.payment_body {
  max-height: 0;
  transition: 0.5s;
  overflow: hidden;
}

.payment_body_expand {
  max-height: 100vh;
}

.payment_body ul {
  list-style: none;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.payment_body ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
}

.payment_body ul li input[type="radio"] {
  height: 15px;
  width: 15px;
  flex-shrink: 0;
}
/* payment ends */

/* Address Modal Container  */

.address_modal {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.checkbox_input {
  display: flex;
  gap: 10px;
  border: 1px solid lightgrey;
  padding: 15px 10px 15px 15px;
  border-radius: 15px;
}

/* Address Modal Container ends */

/* Account Info Modal */
.account_info_modal {
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding-top: 10px;
  padding-bottom: 30px;
}

.billing_address {
  border: 1px solid lightgrey;
  border-radius: 20px;
  height: fit-content;
  position: relative;
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.billing_address > span,
.business_account_main > span {
  display: block;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 15px;
  background: white;
  color: grey;
  padding: 3px;
  letter-spacing: 0.05rem;
}

.add_new_billing_address {
  flex-shrink: 0;
  height: 50px;
  border-radius: 15px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-inline: 15px;
  cursor: pointer;
}

.add_new_billing_address svg {
  height: 20px;
  width: 20px;
}

.add_new_billing_address_added {
  border-radius: 15px;
  border: 1px solid lightgrey;
  padding-inline: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.add_new_billing_address_added p:first-child {
  font-size: 1.1rem;
  display: flex;
  gap: 5px;
}

.add_new_billing_address_added p:nth-child(2),
.add_new_billing_address_added p:nth-child(3) {
  padding-left: 0px;
  color: grey;
}

.business_account_main {
  border: 1px solid lightgrey;
  border-radius: 15px;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.business_account_head {
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-inline: 15px;
}

.business_account {
  max-height: 0;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 15px;
  padding-inline: 15px;
}

.business_account div:first-child {
  margin-top: 5px;
}

.business_account div:last-child {
  margin-bottom: 10px;
}

.business_account_expand {
  max-height: 100vh;
}

/* Account Info Modal ends */

.secured_section {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  font-size: 0.8rem;
}

.secured_section img {
  height: 1.4rem;
  width: auto;
}

.with_icon {
  width: 100%;
  position: relative;
}

.with_icon svg {
  position: absolute;
  height: 20px;
  width: 20px;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
}
.with_icon svg path {
  fill: grey;
}

.btn_loading {
  width: 30px;
  height: 30px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
