.overlay_container {
  padding: 15px 15px 0 20px;
  position: absolute;
  left: 0;
  bottom: -100%;
  height: calc((700px - 65px) / 1.05);
  background: white;
  width: 100%;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: 0.5s;
}

.overlay_container_open {
  bottom: 0;
}

.overlay_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  flex-shrink: 0;
}

.overlay_head h2 {
  font-weight: 500;
  letter-spacing: 0.05rem;
  font-size: 1.3rem;
}

.overlay_head svg {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.overlay_hidden {
  height: calc(100% - 115px);
  overflow: hidden;
}

.overlay_content {
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}
