.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  width: 100%;
  max-width: 360px;
  height: 100%;
  max-height: 640px;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  position: relative;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 0px;
  background: black;
  top: 65px;
}

.overlay_expand {
  animation: overlay_expand 1s;
}

@keyframes overlay_expand {
  0% {
    height: 0px;
  }
  50% {
    height: 100%;
    top: 65px;
  }
  100% {
    top: 100%;
  }
}

.card_head {
  height: 50px;
  border-bottom: 5px solid black;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
}

.card_head svg {
  height: calc(65px - 32px);
  width: auto;
  cursor: pointer;
}

.logo {
  height: 40px;
  width: fit-content;
  overflow: hidden;
}

.logo img {
  height: 100%;
  width: auto;
}

.total {
  margin-left: auto;
  font-size: 1rem
}

.card_content {
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 0px;
  padding-inline: 0px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.closeCross {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

/* mobile */
@media screen and (max-width: 767px) {
  /* .card {
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
  } */

  /* .card_head {
    padding-inline: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  } */

  .card_head {
  }

  .card_content {
    padding-top: 20px;
    padding-inline: 2vw;
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
}

@media screen and (min-width: 1024px) {
}
