.container {
  height: 100%;
  width: 100%;
}

.container_head {
  height: 60px;
  padding-inline: 24px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.container_border {
  height: 5px;
  width: 100%;
  background-color: #e9e9e9;
}

.logo {
  height: calc(65px - 32px);
  aspect-ratio: 1/0.6;
  background: #e9e9e9;
  border-radius: 5px;
}

.container_content {
  height: calc(90vh - 60px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 20px;
  padding-inline: 20px;
  padding-bottom: 30px;
}

.container_content ul {
  list-style: none;
  padding-inline: 0px;
  margin-block: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.container_content ul li {
  height: 60px;
  width: 100%;
  background: #e9e9e9;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.loading::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 10;
  transform: translateX(0);
  animation: move 2s infinite;
}

@keyframes move {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
